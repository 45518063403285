import { Company, Contact } from '@meetingflow/common/Api/data-contracts';
import { DSContactDetail } from './DSContactDetail';
import DSListAndDetailPanel, { Column } from '../../DS/DSListAndDetailPanel';
import { useMemo, useState, useEffect } from 'react';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useLocalStorageState } from '../../../../Hooks/useLocalStorageState';
import {
  useDecisionSiteContacts,
  SortKey,
} from './hooks/useDecisionSiteContacts';
import { DSContactTableLoadingPlaceholder } from './DSContactTableLoadingPlaceholder';
import { DSContactAvatarAndDetails } from './DSContactAvatarAndDetails';
import { DSCompanyAvatarAndDetails } from '../Companies/DSCompanyAvatarAndDetails';
import { TabLabelWithIcon } from '../../Artifacts/DSArtifactPanel.styles';
import { DSStatusMessage } from '../../Components/DSStatusMessage';
import {
  CheckCircle,
  Pending,
  PsychologyOutlined,
  CalendarMonthOutlined,
  SpeakerNotes,
  SpeakerNotesOutlined,
  TipsAndUpdates,
  BarChartOutlined,
  FormatQuoteOutlined,
} from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { DSContactDetailPersonality } from './DSContactDetailPersonality';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { DSTooltip } from '../../DS';
import { DSContactDetailMeetings } from './DSContactMeetings';
import { DSContactDetailAdvice } from './DSContactDetailAdvice';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import { DSSellerHubActivityTable } from '../../Analytics/DSSellerHubActivityTable';
import { DSContactDetailKeyStatements } from './DSContactDetailKeyStatements';
import { DSContactDetailMoments } from './DSContactDetailMoments';

const CONTACTS_PER_PAGE = 50;

export interface DSContactsListDetailProps {
  panelOnly?: boolean;
}

export const DSContactsListDetail = ({
  panelOnly,
}: DSContactsListDetailProps) => {
  const { slug: organizationSlug } = useOrganization();
  const { dealRoom } = useDealRoom();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    searchParams.get('contact'),
  );
  const [searchString, setSearchString] = useState('');
  const [activeTab, setActiveTab] = useState<string>('personality');

  const [sortBy, setSortBy] = useLocalStorageState<SortKey>(
    `${organizationSlug}_CONTACTS_SORT_BY`,
    'lastMeetingDate',
  );

  const [sortOrder, setSortOrder] = useLocalStorageState<'asc' | 'desc'>(
    `${organizationSlug}_CONTACTS_SORT_ORDER`,
    'asc',
  );

  const {
    contacts,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useDecisionSiteContacts({
    organizationSlug: organizationSlug!,
    sortBy,
    sortOrder,
    searchString,
    decisionSiteIds: dealRoom?.id ? [dealRoom?.id] : undefined,
  });

  const handleSort = (newSortKey: SortKey) => {
    if (newSortKey === sortBy) {
      // Toggle order if clicking the same column
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new sort key and default to ascending
      setSortBy(newSortKey);
      setSortOrder('asc');
    }
    // Reset the infinite query when sort changes
    refetch();
  };

  const columns = useMemo(() => {
    const baseColumns: Column<Contact, SortKey>[] = [
      {
        id: 'name',
        label: 'Name',
        minWidth: 170,
        getValue: (contact: Contact) => (
          <DSContactAvatarAndDetails contact={contact} />
        ),
        sortKey: 'name' as SortKey,
      },
    ];

    if (dealRoom?.id) {
      baseColumns.push({
        id: 'userId',
        label: 'DS Member',
        minWidth: 170,
        align: 'center' as const,
        getValue: (contact: Contact) => {
          if (
            dealRoom?.contacts.some(
              (drc: Contact) => drc.email === contact.email,
            )
          ) {
            return (
              <DSTooltip
                title={
                  contact.userId
                    ? 'This contact is a member of this Decision Site'
                    : 'This contact has been invited to this Decision Site'
                }
              >
                {contact.userId ? (
                  <CheckCircle sx={{ color: DEALROOMS_COLORS.peacock }} />
                ) : (
                  <Pending sx={{ color: DEALROOMS_COLORS.cloudburst }} />
                )}
              </DSTooltip>
            );
          } else {
            return <></>;
          }
        },
      });
    }

    return baseColumns;
  }, [dealRoom]);

  const theme = useTheme();
  const isVeryTallScreen = useMediaQuery('(min-height: 1200px)');
  const isTallScreen = useMediaQuery('(min-height: 900px)');
  const isShortScreen = useMediaQuery('(max-height: 600px)');
  const isVeryShortScreen = useMediaQuery('(max-height: 500px)');

  const tabPanelHeight = useMemo(() => {
    if (isVeryTallScreen) return 70;
    if (isTallScreen) return 60;
    if (isShortScreen) return 45;
    if (isVeryShortScreen) return 35;
    return 55; // default for medium height screens
  }, [isVeryTallScreen, isTallScreen, isShortScreen, isVeryShortScreen]);

  const tabConfig = useMemo(
    () => [
      // {
      //   value: 'work-experience',
      //   label: (
      //     <TabLabelWithIcon>
      //       <WorkHistoryOutlined sx={{ fontSize: '1.2rem' }} />
      //       Work Experience
      //     </TabLabelWithIcon>
      //   ),
      //   panel: selectedContactId ? (
      //     <DSContactDetailWorkExperience
      //       contactId={parseInt(selectedContactId, 10)}
      //     />
      //   ) : null,
      // },
      // {
      //   value: 'education',
      //   label: (
      //     <TabLabelWithIcon>
      //       <SchoolOutlined sx={{ fontSize: '1.2rem' }} />
      //       Education
      //     </TabLabelWithIcon>
      //   ),
      //   panel: selectedContactId ? (
      //     <DSContactDetailEducation
      //       contactId={parseInt(selectedContactId, 10)}
      //     />
      //   ) : null,
      // },
      {
        value: 'personality',
        label: (
          <TabLabelWithIcon>
            <PsychologyOutlined
              sx={{
                fontSize: '1.2rem',
                color: DEALROOMS_COLORS.themeSecondary,
              }}
            />
            Personality
          </TabLabelWithIcon>
        ),
        panel: selectedContactId ? (
          <DSContactDetailPersonality
            contactId={parseInt(selectedContactId, 10)}
          />
        ) : null,
      },
      {
        value: 'meetings',
        label: (
          <TabLabelWithIcon>
            <CalendarMonthOutlined
              sx={{
                fontSize: '1.2rem',
                color: DEALROOMS_COLORS.themeSecondary,
              }}
            />
            Meetings
          </TabLabelWithIcon>
        ),
        panel: selectedContactId ? (
          <DSContactDetailMeetings
            contactId={parseInt(selectedContactId, 10)}
          />
        ) : null,
      },
      {
        value: 'key-statements',
        label: (
          <TabLabelWithIcon>
            <FormatQuoteOutlined
              sx={{
                fontSize: '1.2rem',
                color: DEALROOMS_COLORS.themeSecondary,
              }}
            />
            Key Statements
          </TabLabelWithIcon>
        ),
        panel: selectedContactId ? (
          <DSContactDetailKeyStatements
            contactId={parseInt(selectedContactId, 10)}
          />
        ) : null,
      },
      {
        value: 'recommendations',
        label: (
          <TabLabelWithIcon>
            <TipsAndUpdates
              sx={{
                fontSize: '1.2rem',
                color: DEALROOMS_COLORS.themeSecondary,
              }}
            />
            Recommendations
          </TabLabelWithIcon>
        ),
        panel: selectedContactId ? (
          <DSContactDetailMoments
            contactId={parseInt(selectedContactId, 10)}
          />
        ) : null,
      },
      // {
      //   value: 'advice',
      //   label: (
      //     <TabLabelWithIcon>
      //       <SpeakerNotesOutlined
      //         sx={{
      //           fontSize: '1.2rem',
      //           color: DEALROOMS_COLORS.themeSecondary,
      //         }}
      //       />
      //       Communication Style
      //     </TabLabelWithIcon>
      //   ),
      //   panel: selectedContactId ? (
      //     <DSContactDetailAdvice contactId={parseInt(selectedContactId, 10)} />
      //   ) : null,
      // },
      ...(contacts?.find((c) => c.id === parseInt(selectedContactId!, 10))
        ?.userId
        ? [
            {
              value: 'activity',
              label: (
                <TabLabelWithIcon>
                  <BarChartOutlined
                    sx={{
                      fontSize: '1.2rem',
                      color: DEALROOMS_COLORS.themeSecondary,
                    }}
                  />
                  Activity
                </TabLabelWithIcon>
              ),
              panel: selectedContactId
                ? (() => {
                    // Safely find the contact and extract userId
                    const selectedContact = contacts.find(
                      (c) => c.id === parseInt(selectedContactId, 10),
                    );
                    const userId = selectedContact?.userId;

                    // Only render the component if we have a valid userId
                    return userId ? (
                      <Box sx={{ position: 'relative', height: '100%' }}>
                        <DSSellerHubActivityTable
                          userIds={[userId]}
                          hideUserFilter={true}
                          tableMaxHeightOffset={700}
                          instanceId={`contact-activity-${selectedContactId}`}
                        />
                      </Box>
                    ) : (
                      <DSStatusMessage
                        title="No activity data available for this contact"
                        icon={BarChartOutlined}
                      />
                    );
                  })()
                : null,
            },
          ]
        : []),
    ],
    [selectedContactId, contacts],
  );

  // Monitor and set the correct tab when URL changes
  useEffect(() => {
    const tabParam = searchParams.get('tab');
    if (tabParam && tabConfig.some((tab) => tab.value === tabParam)) {
      setActiveTab(tabParam);
    }
    
    // When the user selects a contact, ensure we update state
    const contactParam = searchParams.get('contact');
    if (contactParam !== selectedContactId) {
      setSelectedContactId(contactParam);
    }
  }, [searchParams, tabConfig, selectedContactId]);

  // Handle tab changes, including special handling for activity tab
  const handleTabChange = (newTabValue: string | number) => {
    // Convert to string if it's a number
    const tabValue = String(newTabValue);
    setActiveTab(tabValue);
    
    // Update URL params with the new tab
    const newParams = new URLSearchParams(searchParams);
    newParams.set('tab', tabValue);
    
    // Special handling for the activity tab
    if (tabValue === 'activity' && selectedContactId) {
      // Find the selected contact
      const selectedContact = contacts.find(
        (c) => c.id === parseInt(selectedContactId, 10)
      );
      
      // If we have a valid contact with userId, set userFilter
      if (selectedContact?.userId) {
        // Set the userFilter parameter directly in the URL
        newParams.set('userFilter', String(selectedContact.userId));
      }
    }
    
    setSearchParams(newParams);
  };

  // Watch for URL parameters and update our internal state
  useEffect(() => {
    // Get the current tab from URL params
    const tabFromParams = searchParams.get('tab');
    if (tabFromParams && tabFromParams !== activeTab) {
      setActiveTab(tabFromParams);
    }
  }, [searchParams, activeTab]);

  // Simple handler to close the panel and clean up URL parameters
  const handleClosePanel = () => {
    // Clear the selected contact
    setSelectedContactId(null);
    
    // Remove ALL panel-related URL parameters
    const newParams = new URLSearchParams(searchParams);
    const paramsToRemove = ['contact', 'tab', 'userFilter'];
    
    let hasChanges = false;
    paramsToRemove.forEach(param => {
      if (newParams.has(param)) {
        newParams.delete(param);
        hasChanges = true;
      }
    });
    
    // Only update if we actually removed something
    if (hasChanges) {
      setSearchParams(newParams);
    }
  };

  useEffect(() => {
    const contactId = searchParams.get('contact');
    if (contactId && contactId !== selectedContactId) {
      setSelectedContactId(contactId);
    }
  }, [searchParams, selectedContactId]);

  const loadingPlaceholder = (
    <DSContactTableLoadingPlaceholder
      columns={columns}
      rowsPerPage={CONTACTS_PER_PAGE}
      isFirstPage={!isFetchingNextPage}
    />
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="auto"
      style={{ flex: '0 0 auto' }}
    >
      <DSListAndDetailPanel<Contact, number, SortKey>
        objectList={contacts}
        selectedObjectId={
          selectedContactId ? parseInt(selectedContactId, 10) : null
        }
        onSelectObject={(id) => setSelectedContactId(id.toString())}
        onCloseObjectPanel={handleClosePanel}
        objectTypeKey="contact"
        getItemId={(contact) => contact.id}
        columns={columns}
        sortBy={sortBy}
        sortOrder={sortOrder}
        onSort={handleSort}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onLoadMore={fetchNextPage}
        loadingPlaceholder={loadingPlaceholder}
        renderDetail={(contact) => {
          return <DSContactDetail contactId={contact.id} />;
        }}
        searchEnabled
        searchPlaceholder="Search contacts..."
        onSearch={setSearchString}
        tabs={tabConfig}
        defaultTab="personality"
        defaultIsTabsCollapsed={false}
        areTabsCollapsible={false}
        onTabChange={handleTabChange}
        tabPanelHeightPercentage={tabPanelHeight}
        renderHeader={(contact) => (
          <DSContactAvatarAndDetails contact={contact} size="large" />
        )}
        panelOnly={panelOnly}
      />
    </Box>
  );
};

export default DSContactsListDetail;
