import React from 'react';
import {
  CompaniesApiClient,
  ContactsApiClient,
} from '../../../../Services/NetworkCommon';
import { useQuery } from 'react-query';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';

import {
  PanelWrapper,
  ContentWrapper,
  AdjectiveChipsContainer,
  AdjectiveChip,
  ContentStack,
  ImageColumn,
  InfoColumn,
  ProfileImage,
  ProfileAvatar,
  SkillsChip,
  BackgroundListItem,
  ChipsLabel,
  adjectiveColors,
} from './DSContactDetail.styles';

import { Box, Typography, List, Chip, useTheme } from '@mui/material';
import { Company } from '@meetingflow/common/Api/data-contracts';
import { PendingOutlined, Person2Outlined } from '@mui/icons-material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { DSStatusMessage } from '../../Components/DSStatusMessage';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { DSCompanyAvatarAndDetails } from '../Companies/DSCompanyAvatarAndDetails';

export interface HumanticDataType {
  // status: string;
  // message: string;
  results: {
    first_name: string;
    last_name: string;
    display_name: string;
    user_profile_image: string;
    user_name: string;
    user_id: string;
    user_description: string;
    demographics: {
      age_range: {
        min_age: number;
        max_age: number;
      };
    };
    languages: Record<string, unknown>;
    social_profiles: {
      linkedin?: string;
    };
    photos: Record<string, unknown>;
    websites: string[];
    location: string;
    timezone: string;
    education: Array<{
      school: string;
      degree: string;
      display: string | null;
      start_date: string;
      end_date: string;
    }>;
    work_history: Array<{
      organization: string;
      title: string;
      start_date: string;
      end_date: string;
    }>;
    related_entities: Record<string, unknown>;
    tech_usage: Record<string, unknown>;
    interests: {
      influencers: unknown[];
      interests_and_hobbies: {
        Art: unknown[];
        'Health & Outdoor': unknown[];
        Entertainment: unknown[];
        Sports: unknown[];
        Lifestyle: unknown[];
      };
    };
    social_interactions: Record<string, unknown>;
    social_activity: {
      linkedin?: Array<{
        post_text: string;
        link: string;
        posted_date: string | null;
      }>;
    };
    content_affinity: {
      talks_about: unknown | null;
    };
    persona: {
      sales: {
        //behavioural_factors: Record<string, unknown>;
        email_personalization: {
          advice: {
            Subject: string;
            'Subject Length': string;
            Salutation: string;
            Greeting: string;
            'Emojis/GIFs': string;
            'Bullet Points': string;
            'Closing Line': string;
            'Closing Greeting': string;
            'Complimentary Close': string;
            'Tone Of Words': string;
            'Overall Messaging': string;
            'Length Of Mail': string;
          };
          examples: {
            Subject: string;
            Salutation: string;
            Greeting: string;
            'Emojis/GIFs': string;
            'Bullet Points': string;
            'Closing Line': string;
            'Complimentary Close': string;
            'Tone Of Words': string;
            'Overall Messaging': string;
            'Length Of Mail': string;
          };
          definitions: {
            Subject: string;
            'Subject Length': string;
            Salutation: string;
            Greeting: string;
            'Emojis/GIFs': string;
            'Bullet Points': string;
            'Closing Line': string;
            'Complimentary Close': string;
            'Tone Of Words': string;
            'Overall Messaging': string;
            'Length Of Mail': string;
          };
        };
        cold_calling_advice: {
          insights: {
            'Pattern Interrupt': string;
            Pace: string;
            Tone: string;
            'Tactics To Win': string;
            'Mistakes To Avoid': string;
            'Making The Ask': string;
            'Subconscious Driver': string;
          };
          examples: {
            Greeting: string;
            Opener: string;
            Introduction: string;
            Ask: string;
            Close: string;
          };
        };
        interests: unknown[];
        conversation_starters: unknown[];
        communication_advice: {
          adjectives: string[];
          key_traits: {
            'Risk Appetite': string;
            'Ability To Say No': string;
            Speed: string;
            'Decision Drivers': string;
          };
          description: string[];
          what_to_say: string[];
          what_to_avoid: string[];
          _type: string[];
        };
        profile_url: string;
      };
      hiring: {
        behavioural_factors: {
          need_for_autonomy: {
            score: number;
            level: string;
            order: number;
          };
          action_orientedness: {
            score: number;
            level: string;
            order: number;
          };
          teamwork_skills: {
            score: number;
            level: string;
            order: number;
          };
          general_behavior: {
            score: number;
            level: string;
            order: number;
          };
          attitude_and_outlook: {
            score: number;
            level: string;
            order: number;
          };
          stability_potential: {
            score: number;
            level: string;
            order: number;
          };
          learning_ability: {
            score: number;
            level: string;
            order: number;
          };
        };
        email_personalization: {
          advice: Record<string, string>;
          examples: Record<string, string>;
          definitions: Record<string, string>;
        };
        cold_calling_advice: Record<string, unknown>;
        interests: unknown[];
        conversation_starters: unknown[];
        communication_advice: {
          adjectives: string[];
          description: string[];
          what_to_say: string[];
          what_to_avoid: string[];
          _type: string[];
          personalized_email: string;
          personalized_email_subject: string;
        };
        profile_url: string;
      };
    };
    // mood: Record<string, unknown>;
    personality_analysis: {
      ocean_assessment: {
        agreeableness: {
          score: number;
          level: string;
        };
        conscientiousness: {
          score: number;
          level: string;
        };
        emotional_stability: {
          score: number;
          level: string;
        };
        extraversion: {
          score: number;
          level: string;
        };
        openness: {
          score: number;
          level: string;
        };
      };
      disc_assessment: {
        calculativeness: {
          score: number;
          level: string;
        };
        dominance: {
          score: number;
          level: string;
        };
        influence: {
          score: number;
          level: string;
        };
        steadiness: {
          score: number;
          level: string;
        };
      };
      summary: {
        disc: {
          label: string[];
          description: string[];
          color: string;
          group: string;
          archetype: string;
        };
        ocean: {
          label: string[];
          description: string[];
        };
      };
    };
    skills: string[];
    followers: number;
    prographics: {
      job_level: string;
      education_level: string;
      experience_in_years: number;
      designation: string;
      social_activity_status: string;
    };
  };
  metadata: {
    confidence: {
      score: number;
      level: string;
    };
    status: string;
    status_code: number;
    analysis_status: string;
    linkedin_analysis_status: string;
    analysis_type: string;
  };
  usage_stats: {
    user_profile: {
      remaining: number;
      limit: number;
      consumed: number;
      info: string;
      remaining_extension: number;
      limit_extension: number;
      consumed_extension: number;
      info_extension: string;
      subscription_status: string;
      personalizations_limit: number;
      personalizations_consumed: number;
      remaining_personalizations: number;
      subscription_renewal_date: string;
    };
  };
}

interface DSContactDetailProps {
  contactId: number;
}

export const DSContactDetail: React.FC<DSContactDetailProps> = ({
  contactId,
}) => {
  const organizationSlug = useOrganizationSlug();
  const { organization } = useOrganization();
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();

  const getContrastColor = (hexColor: string) => {
    // Convert hex to RGB
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    // Calculate relative luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance > 0.5 ? '#000000BF' : '#FFFFFFBF';
  };

  const {
    data: contactData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['contact', organization?.slug, contactId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const response = await ContactsApiClient.getContact(
        organization?.slug ?? '',
        contactId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response;
    },
    enabled: !!organization?.slug,
  });

  const contact = contactData?.data;

  const { data: companiesData } = useQuery({
    queryKey: ['companies', organizationSlug, contact?.emailDomain],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return CompaniesApiClient.listCompanies(
        {
          organizationSlug: organizationSlug!,
          domains: contact?.emailDomain ? [contact.emailDomain] : [],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    enabled: !!organizationSlug && !!contact?.emailDomain,
  });

  // Find the company who has a domain that matches the contact's email domain
  const contactCompany = companiesData?.data?.find((company) =>
    company.domains.some((domain) => domain.domain === contact?.emailDomain),
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading contact details</div>;
  }

  if (!contact) {
    return null;
  }

  if (
    contact.humanticStatus === 'PENDING' ||
    contact.humanticStatus === 'IN_PROGRESS'
  ) {
    return (
      <DSStatusMessage
        icon={PendingOutlined}
        title="Profile Processing in Progress"
        subtitle={`We're currently processing ${contact.name}'s profile. Please check back later.`}
        iconColor={DEALROOMS_COLORS.neutralDark}
      />
    );
  }

  if (contact.humanticStatus === 'COMPLETED_FAILURE' || !contact.humanticData || !contact.humanticData?.personality_analysis) {
    return (
      <DSStatusMessage
        icon={Person2Outlined}
        title="Profile Not Available"
        subtitle={`A profile for ${contact.name} is not available.`}
        iconColor="error.main"
        titleColor="error"
        iconSize={48}
      />
    );
  }

  return (
    <PanelWrapper>
      <ContentWrapper>
        <ContentStack direction="row" spacing={3}>
          {contact.humanticData?.user_profile_image && (
            <ImageColumn>
              <ProfileImage
                src={contact.humanticData?.user_profile_image}
                alt={contact.name || ''}
              />
            </ImageColumn>
          )}
          <InfoColumn>
            {contactCompany && (
              <Box
                sx={{
                  marginTop: '.5rem',
                  marginBottom: '1rem',
                  marginLeft: '-.25rem',
                  paddingBottom: '.5rem',
                  borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
                }}
              >
                <DSCompanyAvatarAndDetails
                  size="large"
                  company={contactCompany}
                />
              </Box>
            )}
            {contact.humanticData?.prographics && (
              <Box>
                <List
                  dense
                  sx={{
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {contact.humanticData?.user_description && (
                    <BackgroundListItem>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Description:</strong>{' '}
                        {contact.humanticData.user_description}
                      </Typography>
                    </BackgroundListItem>
                  )}

                  {contact.humanticData.prographics.job_level && (
                    <BackgroundListItem>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Level:</strong>{' '}
                        {contact.humanticData.prographics.job_level}
                      </Typography>
                    </BackgroundListItem>
                  )}
                  {contact.humanticData.prographics.education_level && (
                    <BackgroundListItem>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Education:</strong>{' '}
                        {contact.humanticData.prographics.education_level}
                      </Typography>
                    </BackgroundListItem>
                  )}
                  {contact.humanticData.prographics.experience_in_years && (
                    <BackgroundListItem>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Experience:</strong>{' '}
                        {contact.humanticData.prographics.experience_in_years}{' '}
                        years
                      </Typography>
                    </BackgroundListItem>
                  )}
                  {contact.humanticData.prographics.designation && (
                    <BackgroundListItem>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Role:</strong>{' '}
                        {contact.humanticData.prographics.designation}
                      </Typography>
                    </BackgroundListItem>
                  )}
                </List>

                {contact.humanticData?.skills &&
                  contact.humanticData.skills.length > 0 && (
                    <AdjectiveChipsContainer>
                      <Typography variant="body2" sx={ChipsLabel}>
                        Skills:
                      </Typography>
                      {contact.humanticData.skills.map(
                        (skill: string, index: number) => (
                          <Chip
                            key={skill}
                            label={skill}
                            sx={{
                              ...SkillsChip,
                              backgroundColor:
                                adjectiveColors[index % adjectiveColors.length],
                              color: getContrastColor(
                                adjectiveColors[index % adjectiveColors.length],
                              ),
                            }}
                          />
                        ),
                      )}
                    </AdjectiveChipsContainer>
                  )}

                {contact.humanticData?.persona?.sales?.communication_advice
                  ?.adjectives && (
                  <AdjectiveChipsContainer>
                    <Typography variant="body2" sx={ChipsLabel}>
                      Traits:
                    </Typography>
                    {contact.humanticData.persona.sales.communication_advice.adjectives.map(
                      (adjective: string, index: number) => (
                        <Chip
                          key={index}
                          label={adjective}
                          sx={{
                            ...AdjectiveChip,
                            backgroundColor:
                              adjectiveColors[
                                (adjectiveColors.length - 1 - index) %
                                  adjectiveColors.length
                              ],
                            color: getContrastColor(
                              adjectiveColors[
                                (adjectiveColors.length - 1 - index) %
                                  adjectiveColors.length
                              ],
                            ),
                          }}
                        />
                      ),
                    )}
                  </AdjectiveChipsContainer>
                )}
              </Box>
            )}
          </InfoColumn>
        </ContentStack>
      </ContentWrapper>
    </PanelWrapper>
  );
};
