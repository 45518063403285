import { FC } from 'react';
import { DateTime } from 'luxon';
import {
  StyledArtifactContainer,
  StyledMediaContainer,
  StyledContentContainer,
  StyledArtifactHeader,
  StyledArtifactName,
  StyledArtifactDescription,
  StyledArtifactTimestamp,
  StyledActionsContainer,
} from './DSWelcomeArtifactCard.styles';
import { DealRoomMeetingflowArtifact } from '@meetingflow/common/Api/data-contracts';
import { DSContactAvatar } from '../DS/DSContactAvatar';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from '../Artifacts/ArtifactIconPreview';
import { Box } from '@mui/material';
import MeetingPlanIcon from '@mui/icons-material/EventNote';
import { useMeetingflow } from './Journey/hooks/useMeetingflow';
import { toContentPath } from '../../../Helpers/FileHelpers';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { VideocamOff } from '@mui/icons-material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DecisionSiteMeetingTranscriptInsights } from './Journey/DecisionSiteMeetingTranscriptInsights';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { ArtifactsTableActions } from '../Artifacts/ArtifactsTableActions';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useQueryClient } from 'react-query';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';

interface DSWelcomeMeetingflowArtifactProps {
  meetingflowArtifact: DealRoomMeetingflowArtifact;
}

export const DSWelcomeMeetingflowArtifact: FC<
  DSWelcomeMeetingflowArtifactProps
> = ({ meetingflowArtifact }) => {
  const navigate = useRouterNavigate();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { userId } = useUserProfile();
  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);
  const queryClient = useQueryClient();
  const { meetingflow } = useMeetingflow(
    meetingflowArtifact.meetingflowId.toString(),
  );

  if (!meetingflowArtifact) return null;

  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    const date = DateTime.fromISO(dateString);
    return date.toFormat('MMM d, yyyy');
  };

  const handleClick = (e: React.MouseEvent) => {
    // Don't navigate if clicking on the actions menu
    if (
      e.target instanceof HTMLElement &&
      (e.target.closest('.artifacts-actions-menu') ||
        e.target.closest('.ms-Button'))
    ) {
      e.stopPropagation();
      return;
    }

    // Navigate to the meeting in the journey section
    if (organizationSlug && dealRoomId) {
      navigate(
        `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${meetingflowArtifact.meetingflowId}`,
        { replace: false },
      );
    }
  };

  const refreshDealRoom = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries([
        'dealRoom',
        organizationSlug,
        dealRoomId,
      ]);
    }
  };

  const refetchArtifacts = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries(
        OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
      );
    }
  };

  const handleDelete = async () => {
    await refetchArtifacts();
    await refreshDealRoom();
  };

  return (
    <StyledArtifactContainer onClick={handleClick}>
      <StyledActionsContainer className="artifacts-actions-menu">
        <ArtifactsTableActions
          userId={userId}
          dealRoomRole={dealRoomRole}
          organizationSlug={organizationSlug || ''}
          dealRoomId={dealRoomId || 0}
          refreshDealRoom={refreshDealRoom}
          refetchArtifacts={refetchArtifacts}
          onDelete={handleDelete}
          artifact={meetingflowArtifact}
        />
      </StyledActionsContainer>
      <StyledMediaContainer>
        <ImageWithFallback
          src={
            meetingflow?.callRecording?.thumbnailFileName
              ? toContentPath(
                  meetingflow?.callRecording?.thumbnailFileName,
                  organizationSlug,
                  meetingflow.id,
                )
              : undefined
          }
          alt="Meeting thumbnail"
          fallback={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                minWidth: '300px',
                width: '100%',
                objectFit: 'contain',
              }}
            >
              <ArtifactIconPreview type="MEETINGFLOW" />
            </Box>
          }
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            minWidth: '300px',
          }}
        />
      </StyledMediaContainer>
      <StyledContentContainer>
        <StyledArtifactHeader>
          {meetingflowArtifact.creator && (
            <DSContactAvatar
              contact={meetingflowArtifact.creator}
              size={28}
              sx={{ marginRight: 1 }}
            />
          )}
          <StyledArtifactName>
            {meetingflowArtifact.name ||
              `A Meeting from ${meetingflowArtifact.creator?.name || 'the Team'}`}
          </StyledArtifactName>
        </StyledArtifactHeader>

        {meetingflow?.callRecording?.transcriptAnalysis?.topics ? (
          <StyledArtifactDescription variant="body2">
            <DecisionSiteMeetingTranscriptInsights
              meetingflowId={meetingflowArtifact.meetingflowId}
              showTopicsHeader={false}
              showSpeakerParticipantCards={false}
            />
          </StyledArtifactDescription>
        ) : (
          <StyledArtifactDescription variant="body2">
            {meetingflowArtifact.description || 'No description available'}
          </StyledArtifactDescription>
        )}

        <StyledArtifactTimestamp variant="caption">
          {formatDate(meetingflowArtifact.createdAt)}
        </StyledArtifactTimestamp>
      </StyledContentContainer>
    </StyledArtifactContainer>
  );
};
