import { styled, Box } from '@mui/material';

interface DetailBarContainerProps {
  sidebarWidth?: number;
}

export const DetailBarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'sidebarWidth',
})((props: DetailBarContainerProps) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '1rem',
  width: `100%`,
  maxWidth: `100%`,
  padding: '0',
  gap: '.5rem',
  overflowX: 'auto',
  overflowY: 'hidden',

  // Ensure first child has no left padding
  '& > *:first-of-type': {
    borderLeft: '1px solid #E0E0E0',
  },

  // Cross-browser scrollbar styling
  scrollbarWidth: 'auto',
  scrollbarColor: 'rgba(0, 0, 0, 0.3) transparent',
  WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS

  // Only apply custom scrollbar styling when not on Safari
  '@supports not (-webkit-hyphens:none)': {
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
    },
  },
}));
