import React from 'react';
import { Box } from '@mui/material';
import { DealRoomLinkArtifact } from '@meetingflow/common/Api/data-contracts';
import {
  StyledArtifactContainer,
  StyledMediaContainer,
  StyledContentContainer,
  StyledArtifactHeader,
  StyledArtifactName,
  StyledArtifactDescription,
  StyledArtifactTimestamp,
  StyledActionsContainer,
} from './DSWelcomeArtifactCard.styles';
import { DSContactAvatar } from '../DS/DSContactAvatar';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from '../Artifacts/ArtifactIconPreview';
import { getYouTubeVideoId } from '../utils/youtubeUtils';
import { getLoomVideoId } from '../utils/loomUtils';
import { getVimeoVideoId } from '../utils/vimeoUtils';
import { DSYouTubeEmbed, DSLoomEmbed, DSVimeoEmbed } from '../DS';
import { DateTime } from 'luxon';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { getArtifactLink } from '../utils/artifactUtils';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { ArtifactsTableActions } from '../Artifacts/ArtifactsTableActions';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useQueryClient } from 'react-query';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';

interface DSWelcomeLinkArtifactProps {
  linkArtifact: DealRoomLinkArtifact;
}

export const DSWelcomeLinkArtifact: React.FC<DSWelcomeLinkArtifactProps> = ({
  linkArtifact,
}) => {
  const navigate = useRouterNavigate();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { userId } = useUserProfile();
  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);
  const queryClient = useQueryClient();

  if (!linkArtifact) return null;

  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    const date = DateTime.fromISO(dateString);
    return date.toFormat('MMM d, yyyy');
  };

  // Check for special video links
  const youtubeVideoId = linkArtifact.url
    ? getYouTubeVideoId(linkArtifact.url)
    : null;
  const isYouTubeVideo = !!youtubeVideoId;

  const loomVideoId = linkArtifact.url
    ? getLoomVideoId(linkArtifact.url)
    : null;
  const isLoomVideo = !!loomVideoId;

  const vimeoVideoId = linkArtifact.url
    ? getVimeoVideoId(linkArtifact.url)
    : null;
  const isVimeoVideo = !!vimeoVideoId;

  const handleClick = (e: React.MouseEvent) => {
    // Don't navigate if the click was on an embedded video or iframe
    if (
      e.target instanceof HTMLIFrameElement ||
      (e.target instanceof HTMLElement &&
        (e.target.closest('iframe') || e.target.closest('[data-video-embed]')))
    ) {
      return;
    }

    // Don't navigate if clicking on the actions menu
    if (e.target instanceof HTMLElement && 
        (e.target.closest('.artifacts-actions-menu') || 
         e.target.closest('.ms-Button'))) {
      e.stopPropagation();
      return;
    }

    // Navigate to the artifact detail page
    if (dealRoomId && organizationSlug) {
      const link = getArtifactLink(organizationSlug, dealRoomId, linkArtifact);

      navigate(link, { replace: false });
    }
  };

  const refreshDealRoom = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries(['dealRoom', organizationSlug, dealRoomId]);
    }
  };

  const refetchArtifacts = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries(
        OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId)
      );
    }
  };

  const handleDelete = async () => {
    await refetchArtifacts();
    await refreshDealRoom();
  };

  // Common video embed container styles
  const videoEmbedContainerStyle = {
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
  };

  return (
    <StyledArtifactContainer
      onClick={handleClick}
    >
      <StyledActionsContainer className="artifacts-actions-menu">
        <ArtifactsTableActions
          userId={userId}
          dealRoomRole={dealRoomRole}
          organizationSlug={organizationSlug || ''}
          dealRoomId={dealRoomId || 0}
          refreshDealRoom={refreshDealRoom}
          refetchArtifacts={refetchArtifacts}
          onDelete={handleDelete}
          artifact={linkArtifact}
        />
      </StyledActionsContainer>
      <StyledMediaContainer>
        {isYouTubeVideo ? (
          <Box
            data-video-embed
            onClick={(e) => e.stopPropagation()}
            sx={videoEmbedContainerStyle}
          >
            <DSYouTubeEmbed
              videoId={youtubeVideoId}
              title={linkArtifact.name}
            />
          </Box>
        ) : isLoomVideo ? (
          <Box
            data-video-embed
            onClick={(e) => e.stopPropagation()}
            sx={videoEmbedContainerStyle}
          >
            <DSLoomEmbed videoId={loomVideoId} title={linkArtifact.name} />
          </Box>
        ) : isVimeoVideo ? (
          <Box
            data-video-embed
            onClick={(e) => e.stopPropagation()}
            sx={videoEmbedContainerStyle}
          >
            <DSVimeoEmbed videoId={vimeoVideoId} title={linkArtifact.name} />
          </Box>
        ) : (
          <ImageWithFallback
            src={
              linkArtifact.thumbnailUrl ||
              `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${linkArtifact.url}&size=50`
            }
            fallbackSrc={
              linkArtifact.thumbnailUrl
                ? `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${linkArtifact.url}&size=50`
                : undefined
            }
            alt={linkArtifact.name || 'Link'}
            fallback={
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ArtifactIconPreview type="LINK" />
              </Box>
            }
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
          />
        )}
      </StyledMediaContainer>
      <StyledContentContainer>
        <StyledArtifactHeader>
          {linkArtifact.creator && (
            <DSContactAvatar
              contact={linkArtifact.creator}
              size={28}
              sx={{ marginRight: 1 }}
            />
          )}
          <StyledArtifactName>
            {linkArtifact.name ||
              `A Link from ${linkArtifact.creator?.name || 'the Team'}`}
          </StyledArtifactName>
        </StyledArtifactHeader>

        {linkArtifact.description && (
          <StyledArtifactDescription variant="body2">
            {linkArtifact.description}
          </StyledArtifactDescription>
        )}

        <StyledArtifactTimestamp variant="caption">
          {formatDate(linkArtifact.createdAt)}
        </StyledArtifactTimestamp>
      </StyledContentContainer>
    </StyledArtifactContainer>
  );
};
