import React from 'react';
import { Box } from '@mui/material';
import { DealRoomFileArtifact } from '@meetingflow/common/Api/data-contracts';
import {
  StyledArtifactContainer,
  StyledMediaContainer,
  StyledContentContainer,
  StyledArtifactHeader,
  StyledArtifactName,
  StyledArtifactDescription,
  StyledArtifactTimestamp,
  StyledActionsContainer,
} from './DSWelcomeArtifactCard.styles';
import { DSContactAvatar } from '../DS/DSContactAvatar';
import { DateTime } from 'luxon';
import { ImageWithFallback } from '../../Common/ImageWithFallback';
import { ArtifactIconPreview } from '../Artifacts/ArtifactIconPreview';
import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { getArtifactLink } from '../utils/artifactUtils';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../Hooks/useDealRoomId';
import { ArtifactsTableActions } from '../Artifacts/ArtifactsTableActions';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useQueryClient } from 'react-query';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';

interface DSWelcomeFileArtifactProps {
  fileArtifact: DealRoomFileArtifact;
}

export const DSWelcomeFileArtifact: React.FC<DSWelcomeFileArtifactProps> = ({
  fileArtifact,
}) => {
  const navigate = useRouterNavigate();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const { userId } = useUserProfile();
  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);
  const queryClient = useQueryClient();
  
  if (!fileArtifact) return null;

  // Format date for display
  const formatDate = (dateString?: string) => {
    if (!dateString) return '';
    const date = DateTime.fromISO(dateString);
    return date.toFormat('MMM d, yyyy');
  };

  const handleClick = (e: React.MouseEvent) => {
    // Don't navigate if clicking on the actions menu
    if (e.target instanceof HTMLElement && 
        (e.target.closest('.artifacts-actions-menu') || 
         e.target.closest('.ms-Button'))) {
      e.stopPropagation();
      return;
    }
    
    // Navigate to the artifact detail page
    if (dealRoomId && organizationSlug) {
      const link = getArtifactLink(
        organizationSlug,
        dealRoomId,
        fileArtifact
      );
      
      navigate(link, { replace: false });
    }
  };

  const refreshDealRoom = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries(['dealRoom', organizationSlug, dealRoomId]);
    }
  };

  const refetchArtifacts = async () => {
    if (organizationSlug && dealRoomId) {
      await queryClient.refetchQueries(
        OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId)
      );
    }
  };

  const handleDelete = async () => {
    await refetchArtifacts();
    await refreshDealRoom();
  };

  return (
    <StyledArtifactContainer
      onClick={handleClick}
    >
      <StyledActionsContainer className="artifacts-actions-menu">
        <ArtifactsTableActions
          userId={userId}
          dealRoomRole={dealRoomRole}
          organizationSlug={organizationSlug || ''}
          dealRoomId={dealRoomId || 0}
          refreshDealRoom={refreshDealRoom}
          refetchArtifacts={refetchArtifacts}
          onDelete={handleDelete}
          artifact={fileArtifact}
        />
      </StyledActionsContainer>
      <StyledMediaContainer>
        <ImageWithFallback
          src={fileArtifact.thumbnailUrl && typeof fileArtifact.thumbnailUrl === 'string' && fileArtifact.thumbnailUrl.trim() !== '' ? fileArtifact.thumbnailUrl : undefined}
          alt={fileArtifact.name || 'File'}
          fallback={
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ArtifactIconPreview
                type="FILE"
                fileExtension={fileArtifact.fileName?.split('.')?.pop() || ''}
                mimeType={fileArtifact.mimeType}
              />
            </Box>
          }
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'contain',
          }}
        />
      </StyledMediaContainer>
      <StyledContentContainer>
        <StyledArtifactHeader>
          {fileArtifact.creator && (
            <DSContactAvatar
              contact={fileArtifact.creator}
              size={28}
              sx={{ marginRight: 1 }}
            />
          )}
          <StyledArtifactName>
            {fileArtifact.name ||
              fileArtifact.fileName ||
              `A File from ${fileArtifact.creator?.name || 'the Team'}`}
          </StyledArtifactName>
        </StyledArtifactHeader>

        {fileArtifact.description && (
          <StyledArtifactDescription variant="body2">
            {fileArtifact.description}
          </StyledArtifactDescription>
        )}

        <StyledArtifactTimestamp variant="caption">
          {formatDate(fileArtifact.createdAt)}
        </StyledArtifactTimestamp>
      </StyledContentContainer>
    </StyledArtifactContainer>
  );
};
