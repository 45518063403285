import { useMemo, ReactNode } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { DetailBarContainer } from './DSDetailBar.styles';
import { useDSGlobalSidebar } from '../../../../Context/DSGlobalSidebarContext';

interface DSDetailBarProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

const DSDetailBar = ({ children, sx }: DSDetailBarProps) => {
  const { isSidebarVisible, isExpanded } = useDSGlobalSidebar();

  // Calculate sidebar width based on visibility and expansion state
  const sidebarWidth = useMemo(() => {
    if (!isSidebarVisible) return 0;
    return isExpanded ? 300 : 0; // 300px when expanded, 0px when collapsed
  }, [isSidebarVisible, isExpanded]);

  const boxStyles: SxProps<Theme> = {
    width: `calc(100vw - ${sidebarWidth || 0}px)`,
    maxWidth: `calc(100vw - ${sidebarWidth || 0}px)`,
    overflow: 'hidden',
    position: 'relative',
    padding: '0 0 0 2rem',
    boxSizing: 'border-box',
    marginLeft: '-2rem',
    marginRight: '-2rem',
    top: '-3rem',
    ...(sx || {}),
  };

  return (
    <Box sx={boxStyles}>
      <DetailBarContainer sidebarWidth={sidebarWidth}>
        {children}
      </DetailBarContainer>
    </Box>
  );
};

export default DSDetailBar;
